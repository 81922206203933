//MIXIN per l'estat FOCUS a links i butons
@mixin focus-estil(
    $colorFocus: var(--color-stroke-semantic-accesibility-focus),
    $radi: 0,
    $marge: var(--spacing-space-10),
    $ample: var(--spacing-space-10)
) {
    &:focus {
        outline: none;
    }
    &:focus-visible {
        outline: $ample solid $colorFocus;
        outline-offset: $marge;
        @if not($radi==0) {
            border-radius: $radi;
        }
    }
}
