@use '../../styles/_global-variables.scss' as global;

.mask {
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 250;
}
.marginAuto {
    margin: auto;
}
.rLoginWrapper {
    width: 37rem;
    margin: 1rem;
    background-color: #fff;
    padding: 1rem;
    border-radius: 0.2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    min-height: max-content;
}
.imDtyLogo {
    align-self: center;
    margin-top: 2rem;
}
.rLoginError {
    padding: 1rem;
    margin: 0 1rem;
    height: 50px;
    color: red;
}
.rFormLogin {
    display: flex;
    flex-direction: column;
    padding: 0rem 1rem 1rem 1rem;
    width: 350px;
}
.rName {
    color: rgb(63, 63, 63);
    font-weight: 100;
    font-size: 1.2rem;
}
.opacity {
    opacity: 1;
}
.rCamp {
    font-weight: 400;
    height: 3rem;
    background: #fff;
    color: #000;
    border: 1px solid #babcbf;
    border-radius: 5px;
    margin: 0.625rem;
    position: relative;
    clear: both;
    padding: 0 10px;
}
.rCampButton {
    font-weight: 400;
    height: 3rem;
    background: #5600c2;
    color: #fff;
    border: 1px solid #45009b;
    border-radius: 5px;
    margin: 0.625rem;
    position: relative;
    clear: both;
    padding: 0 10px;
}
.rCampLabel {
    display: block;
    color: lighten(#000, 60%);
    font-size: 1rem;
    font-weight: 500;
    position: absolute;
    top: 3px;
}

.rCampInput {
    border: none;
    border-radius: 0;
    position: absolute;
    bottom: 0px;
    &:focus-visible {
        outline: none;
    }
    z-index: 2;
    padding: 0;
    width: 90%;
    background-color: transparent;
    height: 28px;
}
.empty {
    position: absolute;
    top: 10px;
    font-size: 1rem;
    font-weight: 500;
}
.rCampEmpty {
    background-color: #e8f0fe;
}
.valueError {
    border-color: #ff7e7e;
}
