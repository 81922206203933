@use '../../../styles/noticies/_global-variables.scss' as global;

.redlNotRenderableInfo {
    position: fixed;
    left: 50%;
    top: 4%;
    transform: translateX(-50%);
    width: 70%;
    height: auto;
    background-color: white;
    color: #333333;
    align-items: center;
    border-radius: 0.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2rem;
    min-height: max-content;
    overflow: auto;
    padding-left: 64px;
    padding-right: 36px;
    padding-top: 16px;
    padding-bottom: 16px;
    z-index: 201;
}

.redlNotRenderableInfo::before{
    content: url('/_resources/icones/avisos/warning_groc.svg');
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
    width: 28px;
    height: 28px;
}

.redlNotRenderableInfo__text {
    font-size: 1rem;
    font-weight: 100;
    font-family: Arial, Helvetica, sans-serif !important;
    line-height: 1.5rem;
}

.closeButton {
    background: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 10px;
    width: 14px;
    height: 14px;
}

.redlNotRenderableInfo {
    opacity: 1;
    transition: opacity 0.3s ease;
    pointer-events: none;
    pointer-events: auto;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.4);
}

.redlNotRenderableInfo.fading {
    opacity: 0;
}

.redlNotRenderableInfo.notVisible {
    display: none;
}