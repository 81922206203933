@use '_portal-variables' as tresplay;

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    // -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font: -apple-system-body; // Això fa que la mida de text de sistema dels macOS i iOS funcioni.
    scroll-behavior: smooth;

    &.catinfo {
        scroll-behavior: auto;
    }
}

html.macos {
    font-size: 100%; // Bugfix mida text a Safari, quedava més petit que la resta de navegadors.
}

body.trescat {
    padding: 0;
    margin: 0;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    background-color: #111;
    color: tresplay.$colorText5;

    &.is-modal-fullscreen {
        overflow: hidden;
    }
}
body.noticies {
    padding: 0;
    margin: 0;
    font-family: 'Bw 3cat', Helvetica, Arial, sans-serif;
    background-color: #fff;
    color: #111617;

    &.is-modal-fullscreen {
        overflow: hidden;
    }
    &.is-modal-fullscreen-keep-scrollbar {
        overflow: hidden;
        padding-right: 17px;
    }
}

section {
    display: flex;
}

@media (prefers-reduced-motion) {
    * {
        transition: all 0s;
    }
}

// ESTILS GENÈRICS

:root {
    --headline_xl: 3.653rem;
    --headline_l: 1.688rem;
    --headline_m: 1.5rem;
    --headline_s: 1.312rem;
    --headline_xs: 1.188rem;
    --body_l: 1.062rem;
    --body_m: 0.938rem;
    --body_s: 0.875rem;
    --body_xs: 0.75rem;
    --body_xxs: 0.688rem;
    --body_xxxs: 0.438rem;

    // VARIABLES TRANSVERSALS
    --trans-color-background-skeleton-neutral-bold: #111;
}
h1 {
    font-size: var(--headline_l);
}
h2 {
    font-size: var(--headline_m);
}
h3 {
    font-size: var(--headline_s);
}
h4,
h5,
h6 {
    font-size: var(--headline_xs);
}
p {
    font-size: var(--body_m);
}
strong {
    font-weight: bold;
}

#__next {
    & > div:not([class]) > div:not([class]) {
        min-height: 100vh; // PER POSICIONAR EL PEU EN PAGINES CURTES
    }
}

a {
    color: inherit;
    text-decoration: none;
}

*:focus {
    // OUTLINE PROVISIONAL PENDENT DE DECIDIR
    outline: 1px ridge rgba(255, 255, 255, 0.6);
    border-radius: 2px;
}

* {
    box-sizing: border-box;
}
.react-grid-item {
    border: 2px solid pink;
    padding: 10px;
    overflow: auto;
}

//ELIMINEM CREU INPUT CHROME
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    display: none;
}

//SR-ONLY
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
