@use '../../../styles/noticies/_global-variables.scss' as global;
@use '../../../styles/noticies/utils/mixins';

// ESTILS GENÈRICS
.boto {
    display: inline-flex;
    font-size: var(--font-size-body-m);
    line-height: var(--font-height-body-m);
    align-items: center;
    overflow: hidden;
    border: none;
    column-gap: var(--spacing-space-100);
    cursor: pointer;
    padding: 0 var(--spacing-space-200);
    min-height: 36px; // mida large per defecte
    border-radius: var(--rounded-500);
    justify-content: center;
    &:focus {
        border-radius: var(--rounded-500);
    }
}

.boto.regular {
    min-height: 28px;
    font-size: var(--font-size-body-m);
    line-height: var(--font-height-body-m);
    padding: 0 var(--spacing-space-200);
}
.boto.small {
    min-height: 24px;
    font-size: var(--font-size-body-s);
    line-height: var(--font-height-body-s);
    padding: 0 var(--spacing-space-200);
}

.icona {
    margin-left: 0;
    margin-bottom: 0;
    width: 20px;
    height: 20px;
    vertical-align: baseline;
    position: relative;
    display: grid;
    &.dreta {
        margin-right: 0;
    }
}
// TEMES
.boto.qa {
    margin: 2px;
}
.boto.ple,
.boto.ple100 {
    background-color: var(--color-background-button-3catinfo-default);
    color: var(--color-text-link-neutral-default);
    border-radius: var(--rounded-400);
    font-weight: 700;
    &:hover,
    &:active {
        background-color: var(--color-background-button-3catinfo-hover);
        outline: none;
    }
    @include mixins.focus-estil();
    &.fonsContenidorNegre {
        &:focus-visible {
            outline: 2px solid var(--color-stroke-semantic-accesibility-focus-inverse);
        }
    }
    &.fonsContenidorTaronja {
        &:focus-visible {
            outline: 2px solid var(--color-stroke-semantic-accesibility-focus-accent);
        }
    }
}

.boto.ple100 {
    width: 100%;
}

.boto.ple3cat100 {
    background: var(--3cat-500);
    color: var(--neutrals-0);
    width: 100%;
    font-weight: 700;
    border: 1px solid transparent;
    transition: all 0.2s;
    &:hover {
        border: 1px solid var(--3cat-500);
        color: var(--3cat-500);
        background: transparent;
    }
}
.boto.lined3cat100 {
    background: transparent;
    color: var(--neutrals-1000);
    border: 1px solid var(--neutrals-1000);
    width: 100%;
    font-weight: 700;
    transition: all 0.2s;
    &:hover {
        border: 1px solid transparent;
        color: var(--neutrals-0);
        background: var(--neutrals-1000);
    }
}

.boto.transparent {
    background-color: transparent;
    &:hover {
        color: var(--color-text-link-3catinfo);
    }
    @include mixins.focus-estil();
}
.boto.gris,
.boto.autorgris {
    background-color: var(--color-background-skeleton-neutral-light);
    &:hover,
    &:active,
    &:focus {
        background-color: var(--color-background-button-neutral-default);
        color: var(--color-text-link-neutral-default-inverse);
    }
}

.boto.alignDreta {
    padding-right: 0;
}

.boto.llegeixMes {
    padding: 0;
    color: var(--color-text-link-3catinfo);
    font-size: var(--font-size-body-m);
    font-weight: 700;
    line-height: var(--font-height-body-m);
    path {
        fill: currentColor;
    }
    &:hover {
        color: var(--color-stroke-3catinfo-hover);
    }
    @include mixins.focus-estil();
}

.boto.lined,
.boto.lined100 {
    padding: var(--spacing-space-0) var(--spacing-space-200);
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: var(--rounded-500);
    color: var(--color-text-link-3catinfo);
    border: 1px solid currentColor;
    span {
        font-weight: 700;
    }
    path {
        fill: currentColor;
    }
    &:hover {
        background-color: var(--color-stroke-3catinfo-hover);
        color: var(--color-text-link-neutral-default);
        border: 1px solid var(--color-stroke-3catinfo-hover);
    }
    &.secondary {
        color: var(--color-text-link-neutral-default);
        &:hover {
            background-color: var(--color-text-link-neutral-default);
            color: var(--color-text-default-inverse);
            border: 1px solid var(--color-text-link-neutral-default);
        }
    }
    @include mixins.focus-estil();
}

.boto.lined100 {
    width: 100%;
}
.boto.link3CatMagenta {
    display: flex;
    min-height: 40px;
    padding: var(--spacing-space-0) var(--spacing-space-200);
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-body-m);
    line-height: var(--font-height-body-m);
    font-style: normal;
    font-weight: 700;
    border-radius: var(--rounded-1000);
    border: 1px solid var(--3cat-500);
    color: var(--3cat-500);

    &:hover,
    &:focus,
    &:active {
        color: var(--color-text-link-neutral-default-inverse);
        background-color: var(--3cat-500);
    }
}

.boto.link3cat {
    display: flex;
    min-height: 40px;
    padding: var(--spacing-space-0) var(--spacing-space-200);
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-body-s);
    line-height: var(--font-height-body-s);
    font-style: normal;
    font-weight: 700;
    gap: var(--spacing-space-200);
    border-radius: var(--rounded-1000);
    border: 1px solid var(--color-stroke-neutral-default, #dce3e5);
    &:hover,
    &:focus,
    &:active {
        color: var(--3cat-500);
        background-color: var(--neutrals-0);
    }
}

.boto.dark {
    &:hover,
    &:active {
        background-color: var(--color-background-button-3catinfo-hover-inverse);
    }
    @include mixins.focus-estil($colorFocus: var(--color-stroke-semantic-accesibility-focus-inverse));
}

.boto.fonsHoverTransparent {
    &:focus,
    &:active {
        background-color: var(--color-background-button-3catinfo-default);
    }

    &:hover {
        background-color: transparent;
    }
}

.boto.inset {
    width: 32px;
    min-height: 32px;
    margin: 5px;
    background-color: black;
    padding: 0;
    .icona {
        margin: 0;
        width: 18px;
        height: 18px;
    }
    // >720
    @media #{global.$VP_BEYOND_MOBILE} {
        width: 28px;
        height: 28px;
    }

    // >1304
    @media #{global.$VP_BEYOND_TP} {
        width: 32px;
        height: 32px;

        .icona {
            width: 22px;
            height: 22px;
        }
    }

    // >1920
    @media #{global.$VP_LARGE} {
        width: 36px;
        height: 36px;
    }
}

.boto.segueix {
    display: flex;
    padding: var(--spacing-space-0) var(--spacing-space-10) var(--spacing-space-0) var(--spacing-space-200);
    justify-content: center;
    align-items: center;
    border-radius: var(--rounded-500);
    background: var(--color-background-skeleton-neutral-medium);
    color: var(--color-text-link-neutral-default-inverse);
    margin-right: 10px;

    span:first-child {
        font-size: var(--font-size-body-s);
        font-style: normal;
        font-weight: 700;
        line-height: var(--font-height-body-s);
    }
    &:not(.small) {
        .icona {
            @media #{global.$VP_ALL_MOBILE} {
                width: 20px;
                height: 20px;
            }
            @media #{global.$VP_BEYOND_TP} {
                width: 35px;
                height: 35px;
            }
        }
    }

    .iconWrapper .iconaPrincipal {
        circle {
            fill: var(--neutrals-1000);
        }
        path {
            fill: var(--neutrals-400);
        }
    }

    .iconWrapper:hover .iconaPrincipal {
        circle {
            fill: var(--neutrals-400);
        }
        path {
            fill: var(--neutrals-1000);
        }
    }

    &:focus .iconWrapper .iconaPrincipal {
        circle {
            stroke-width: 0;
        }
    }

    &:focus-visible .iconWrapper .iconaPrincipal {
        circle {
            r: 15px;
            fill: var(--neutrals-400);
            stroke-width: var(--spacing-space-10);
            stroke: var(--color-stroke-semantic-accesibility-focus-inverse);
        }
        path {
            fill: var(--neutrals-1000);
        }
    }

    &:hover .iconWrapper .iconaPrincipal {
        circle {
            fill: var(--neutrals-400);
        }
        path {
            fill: var(--neutrals-1000);
        }
    }
}

.boto.fonsTaronja {
    .iconWrapper .iconaPrincipal {
        circle {
            fill: var(--color-background-button-3catinfo-default);
        }
        path {
            fill: var(--neutrals-1000);
        }
    }

    .iconWrapper:hover .iconaPrincipal {
        circle {
            fill: var(--color-background-button-3catinfo-hover);
        }
        path {
            fill: var(--neutrals-1000);
        }
    }

    &:hover .iconWrapper .iconaPrincipal {
        circle {
            fill: var(--color-background-button-3catinfo-hover);
        }
        path {
            fill: var(--neutrals-1000);
        }
    }
}

.boto.disabled,
.boto.disabled:focus {
    background-color: var(--neutrals-800);
    color: var(--neutrals-500);
    outline: none;
    cursor: default;
    pointer-events: none;
    .icona {
        filter: brightness(0) invert(1);
        opacity: 0.4;
    }
}

.boto.twitter {
    display: flex;
    height: 32px;
    min-height: 32px;
    padding: 0 var(--spacing-space-200);
    justify-content: center;
    align-items: center;
    border-radius: var(--rounded-1000);
    background: var(--neutrals-900);
    color: var(--color-text-link-neutral-default-inverse);
    @include mixins.focus-estil($radi: var(--rounded-400));
}
.boto.debug {
    background-color: var(--3cat-700);
    color: #fff;
    font-size: 0.8rem;
    padding: 8px;
    height: auto;
}

.boto.fletxa_simple_amunt,
.boto.fletxa_simple_avall {
    position: relative;
}

// BOTONS PER DISPOSITIU I VIEWPORT
// S < 767
@media #{global.$VP_ALL_MOBILE} {
    .boto.segueix {
        min-height: 32px;
        &:not(.small) {
            .icona {
                width: 28px;
                height: 28px;
            }
        }
    }
    .boto.link3cat {
        min-height: 28px;
    }
    .autorgris {
        min-height: 44px;
        padding: 0 32px;
    }
}

// M > 720
@media #{global.$VP_BEYOND_MOBILE} {
    .boto {
        min-height: 40px;
        padding: 0 var(--spacing-space-300);

        &.regular {
            min-height: 32px;
        }
        &.small {
            min-height: 28px;
        }
        &:not(.small) {
            .icona {
                width: 28px;
                height: 28px;
            }
        }
    }
    .boto.segueix {
        min-height: 32px;
    }
    .autorgris {
        min-height: 56px;
        padding: 0 32px;
    }
}

// L > 1304
@media #{global.$VP_BEYOND_TP} {
    .boto.segueix {
        height: 32px;
        &:not(.small) {
            .icona {
                width: 28px;
                height: 28px;
            }
        }
    }
    .boto {
        min-height: 40px;
        &.regular {
            min-height: 32px;
        }
        &.small {
            min-height: 28px;
        }
    }
    .autorgris {
        min-height: 56px;
        padding: 0 32px;
    }
}

// XL > 1920
@media #{global.$VP_LARGE} {
    .boto {
        min-height: 40 px;
        padding: 0 var(--spacing-space-400);
        &.regular {
            min-height: 40px;
        }
        &.small {
            min-height: 32px;
        }
        &.autorgris {
            min-height: 58px;
        }
        &.twitter {
            min-height: 40px;
        }
    }
}
